import { Breadcrumb, Col, Row } from 'antd';
import { Date, Header, InternalContainer, PageContent, StyledBreadcrumb, StyledImage } from './basic-styles';
import { Link, graphql } from 'gatsby';

import DOMPurify from 'isomorphic-dompurify';
import PublicLayout from '../layout/public-layout';
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import styled from '@emotion/styled';

type ArticleProps = {
  data: {
    article: {
      article_date: string;
      translation: {
        article_title: string;
        article_body: string;
      };
      article_author: {
        first_name: string;
        last_name: string;
      };
      article_media: {
        data: {
          full_url: string;
        };
      };
      article_type: {
        article_type_value: string;
        article_type_slug: string;
      };
    };
  };
};

const ArticleContainer = styled(InternalContainer)`
  padding-top: 40px;
`;

const ArticleHeader = styled(Header)`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const ArticleDate = styled(Date)`
  padding-bottom: 48px;
`;

const ArticleImage = styled(StyledImage)`
  padding-bottom: 48px;
`;

// The query used to provide the page data
export const query = graphql`
  query($id: Int!, $language: String) {
    article: directusArticle(directusId: { eq: $id }) {
      article_date(formatString: "MMM DD, YYYY")
      article_author {
        first_name
        last_name
      }
      article_media {
        data {
          full_url
        }
      }
      translation(language: $language) {
        article_title
        article_body
      }
      article_type {
        article_type_value
        article_type_slug
      }
    }
  }
`;

const Article = ({
  data: {
    article: {
      article_date,
      translation: { article_title, article_body },
      article_type: { article_type_value, article_type_slug },
      article_media,
    },
  },
}: ArticleProps) => {
  const hasImage = article_media?.data?.full_url ? true : false;
  return (
    <PublicLayout seoTitle="">
      <ArticleContainer>
        <Row justify="center">
          <Col xs={24}>
            <StyledBreadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={article_type_slug}>{article_type_value}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Article</Breadcrumb.Item>
            </StyledBreadcrumb>
            <ArticleHeader>{article_title}</ArticleHeader>
            <ArticleDate>{article_date}</ArticleDate>
          </Col>
          {hasImage && (
            <Col lg={16} md={24}>
              <ArticleImage src={article_media.data.full_url} />
            </Col>
          )}
          <Col xs={24}>
            <PageContent dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article_body) }} />
          </Col>
        </Row>
      </ArticleContainer>
    </PublicLayout>
  );
};

export default Article;
